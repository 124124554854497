import React, { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { Select, Tooltip } from 'antd'
import { InputData, GetSelectOptions, Option } from '../../../types'

const { Option: AntdOption } = Select
interface SelectInputProps {
    data: InputData
    lastInputUpdated?: Record<string, unknown>
    getFieldValue: (inputName: string) => unknown
    getSelectOptions?: GetSelectOptions
}

const SelectInput: FunctionComponent<SelectInputProps> = ({
    data,
    getSelectOptions,
    getFieldValue,
    lastInputUpdated,
    ...props
}) => {
    const [loading, setLoading] = useState(true)
    const [options, setOptions] = useState<Option[]>([])
    const [finalQuery, setfinalQuery] = useState<string>('')

    const handleOptionsFetch = useCallback(
        async (url: string) => {
            if (!getSelectOptions) return
            setLoading(true)
            const { data: optionsData } = await getSelectOptions(url)

            if (optionsData) {
                Array.isArray(optionsData)
                    ? setOptions(optionsData.sort((a, b) => (a.label > b.label ? 1 : -1)))
                    : setOptions([optionsData])
            }

            setLoading(false)
        },
        [getSelectOptions, setOptions]
    )

    useEffect(() => {
        const addQuery = data.addQuery

        if (!addQuery) return

        const nameLastInputUpdated = lastInputUpdated && Object.keys(lastInputUpdated)[0]

        const someAddQueryValueChange = addQuery?.some(
            (inputQuery) => inputQuery.inputNameRelated === nameLastInputUpdated
        )
        const finalQueryExist = !!finalQuery.length

        if (finalQueryExist && !someAddQueryValueChange) return

        const completeQuery =
            addQuery?.reduce<string>((inputQuery, currentQueryConfig) => {
                const inputValue = getFieldValue(currentQueryConfig.inputNameRelated)
                const query = `${currentQueryConfig.queryName}=${inputValue}`

                if (inputQuery === '') return `?${query}`
                return `${inputQuery}&${query}`
            }, '') || ''

        setfinalQuery(completeQuery)
    }, [data.addQuery, handleOptionsFetch, getFieldValue, lastInputUpdated, finalQuery])

    useEffect(() => {
        if (!data.optionsUri) return
        if (data.addQuery && !finalQuery.length) return

        handleOptionsFetch(data.optionsUri + finalQuery)
    }, [data, handleOptionsFetch, finalQuery])

    return (
        <Select
            style={{
                width: data.width
            }}
            {...props}
            loading={loading}
            filterOption={true}
            optionFilterProp="label"
            showSearch
        >
            {options.map(({ label, value, tooltip }, optionKey: number) => (
                <AntdOption key={optionKey} value={value} label={label}>
                    <Tooltip title={tooltip} placement="top">
                        {label}
                    </Tooltip>
                </AntdOption>
            ))}
        </Select>
    )
}

export default SelectInput
