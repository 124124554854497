import { AppliedFilters, OptionSelect, RangeType, TripType } from '../../type'
import { formatNumberToTime } from '../../utils'

export const handleGeneralFilters = (prevState: AppliedFilters[], id: string, group?: string): AppliedFilters[] => {
    switch (group) {
        case TripType.general:
            return [
                ...prevState,
                {
                    id: `${id}`,
                    path: `filterValues.${id}`,
                    type: 'boolean',
                    value: true,
                    filterGroup: 'general'
                }
            ]

        case TripType.ida:
            return [
                ...prevState,
                {
                    id: `${id}_ida`,
                    path: `journeys.0.filterValues.${id}`,
                    type: 'boolean',
                    value: true,
                    filterGroup: 'general'
                },
                {
                    id: `${id}_vuelta`,
                    path: `journeys.1.filterValues.${id}`,
                    type: 'boolean',
                    value: true,
                    filterGroup: 'general'
                }
            ]

        default:
            return [
                ...prevState,
                {
                    id: `${id}`,
                    path: `journeys.0.filterValues.${id}`,
                    type: 'boolean',
                    value: true,
                    filterGroup: 'general'
                }
            ]
    }
}

const formatRangeInputValue = (value: number[]) => {
    if (!Array.isArray(value)) return []
    return value.map((option) => formatNumberToTime(option))
}

const handleSelectValue = (option: unknown) => {
    if (option && typeof option === 'object') {
        const selectOption = option as OptionSelect
        return selectOption.value
    }
    return option
}
const formatSelectInputValue = (value: unknown) => {
    if (!Array.isArray(value)) return handleSelectValue(value)
    return value.map(handleSelectValue)
}

export const formatAppliedFilterValue = (type: string, value: unknown, rangeType?: string): unknown => {
    if (type === 'range' && rangeType === RangeType.time) return formatRangeInputValue(value as number[])
    if (type === 'select') return formatSelectInputValue(value)
    return value
}
