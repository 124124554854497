import React, { FunctionComponent } from 'react'
import { Badge, Image } from 'antd'

interface ImageRibbonProps {
    src?: string
    width?: number
}

const ImageRibbon: FunctionComponent<ImageRibbonProps> = ({ src, width = 35, children }) => (
    <Badge.Ribbon
        className="provider-ribbon"
        text={src && <Image width={width} src={src} preview={false} />}
        placement="start"
    >
        {children}
    </Badge.Ribbon>
)

export default ImageRibbon
