import React, { FunctionComponent } from 'react'
import { BackTop } from 'antd'
import { Icons, validateIconType } from '../Icons'

const ScrollToTop: FunctionComponent = () => {
    return (
        <BackTop>
            <div className="scrollTop">
                <Icons icon={validateIconType('arrowUp')} />
            </div>
        </BackTop>
    )
}

export default ScrollToTop
