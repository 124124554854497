import { useEffect } from 'react'

export function useCustomTabFavicon(faviconHref?: string): void {
    const handleFavicon = (href: string) => {
        const favicon = document.getElementById('favicon') as HTMLLinkElement
        if (favicon !== null) {
            favicon.href = href
        }
    }

    useEffect(() => {
        if (faviconHref) handleFavicon(faviconHref)
    }, [faviconHref])
}
