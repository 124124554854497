import React, { FunctionComponent } from 'react'
import { Row, Col } from 'antd'
import OrderInputs from '../OrderInputs'
import Tags from '../Tags'

interface TagsOrderSectionProps {
    filterBtn: string
}

const TagsOrderSection: FunctionComponent<TagsOrderSectionProps> = ({ filterBtn }) => {
    return (
        <Row className="tagsOrder-row">
            <Col span={15}>
                <Tags filterBtn={filterBtn} />
            </Col>
            <Col className="order-wrapper" span={9}>
                <OrderInputs />
            </Col>
        </Row>
    )
}

export default TagsOrderSection
