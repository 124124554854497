import React, { FunctionComponent, Fragment, useEffect } from 'react'
import { Row, Col, Radio, Tooltip, Typography } from 'antd'
import Price from '../../Price/Price'
import { Icons, validateIconType } from '../../../general/Icons'
import { SelectedFare, FaresContent, FaresColSize } from '../interface'
import FareTableItems from './FareTableItems'
import { getColSpan } from '../utils'

enum PriceSizeEnum {
    'S' = 5,
    'M' = 4,
    'L' = 3
}

type FareTableContentProps = {
    data: FaresContent[]
    selectedFare?: SelectedFare
    setSelectedFare?: (value: SelectedFare) => void
    colSizeDictionary: FaresColSize
}

const { Text } = Typography

const FareTableContent: FunctionComponent<FareTableContentProps> = ({
    data,
    selectedFare,
    setSelectedFare,
    colSizeDictionary
}) => {
    useEffect(() => {
        if (!data[0].col7) {
            return
        }
        if (selectedFare?.solutionId === data[0].solutionId) {
            setSelectedFare?.({
                solutionId: data[0].solutionId,
                fareBasis: data[0].fareBasis,
                price: data[0].col7.price.money.amount
            })
        }
    }, [])
    return (
        <Fragment>
            {data.map((fare, ind) => (
                <Row align="stretch" className="fare-row" justify="center" key={ind}>
                    <Col span={getColSpan(colSizeDictionary, 0)} className="fare-col fareName">
                        <Row align="middle">
                            {fare.col7 && (
                                <Col className="radioButton" span={4}>
                                    <Radio
                                        value={fare.solutionId}
                                        checked={selectedFare?.solutionId === fare.solutionId}
                                        onClick={() => {
                                            if (fare.col7)
                                                setSelectedFare?.({
                                                    solutionId: fare.solutionId,
                                                    fareBasis: fare.fareBasis,
                                                    price: fare.col7.price.money.amount
                                                })
                                        }}
                                    />
                                </Col>
                            )}

                            <Col span={fare.col7 ? 20 : 24}>
                                {fare.col1.map((fareFamily, i) => (
                                    <div key={i + fareFamily.fareName} className="fare-type-name">
                                        <Tooltip title={fareFamily.tooltip}>
                                            <span>
                                                <Icons icon={validateIconType(fareFamily.icon)} />
                                            </span>
                                        </Tooltip>
                                        <Text ellipsis={{ tooltip: true }}>{fareFamily.fareName}</Text>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Col>

                    <Col span={getColSpan(colSizeDictionary, 1)} className="fare-col">
                        <FareTableItems data={fare.col2} />
                    </Col>

                    <Col span={getColSpan(colSizeDictionary, 2)} className="fare-col">
                        <FareTableItems data={fare.col3} />
                    </Col>

                    <Col span={getColSpan(colSizeDictionary, 3)} className="fare-col">
                        <FareTableItems data={fare.col4} />
                    </Col>

                    <Col span={getColSpan(colSizeDictionary, 4)} className="fare-col">
                        <FareTableItems data={fare.col5} />
                    </Col>

                    <Col span={getColSpan(colSizeDictionary, 5)} className="fare-col">
                        <FareTableItems data={fare.col6} />
                    </Col>

                    {fare.col7 && (
                        <Col span={getColSpan(colSizeDictionary, 6)} className="fare-col fare-price">
                            <Price
                                money={fare.col7.price.money}
                                status={fare.col7.price.status}
                                size={PriceSizeEnum.S}
                            />
                        </Col>
                    )}
                </Row>
            ))}
        </Fragment>
    )
}

export default FareTableContent
