import React, { FunctionComponent } from 'react'
import { Row, Col, Typography } from 'antd'
import { FaresHeaderData, FaresColSize } from '../interface'
import { getColSpan } from '../utils'

const { Text } = Typography

type FareTableHeaderProps = {
    data: FaresHeaderData
    colSizeDictionary: FaresColSize
}

const FareTableHeader: FunctionComponent<FareTableHeaderProps> = ({ data, colSizeDictionary }) => {
    return (
        <Row align="middle" justify="center">
            {data.map(({ title }, index) => (
                <Col key={index} span={getColSpan(colSizeDictionary, index)} className="fare-header-col">
                    <Text strong>{title}</Text>
                </Col>
            ))}
        </Row>
    )
}

export default FareTableHeader
