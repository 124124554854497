import React from 'react'
import { Typography } from 'antd'
import { TitleProps } from 'antd/lib/typography/Title'

const { Title } = Typography

const TripoActionTitle: React.FunctionComponent<TitleProps> = (props) => (
    <Title {...props} className="tripo-ant-action-title" />
)

export default TripoActionTitle
