import React, { useCallback, useEffect, useState } from 'react'
import { TripoMapModel } from './TripoMapModel'
import { Image } from 'antd'

type TripoMapProps = {
    locations: TripoMapModel[]
    label?: string
    width?: number
    height?: number
    center?: TripoMapModel
    distanceToCenter?: string
    googleMapsApiKey?: string
    onMapHeightSet?: (height: number | string) => void
}

const heightRatioFromGoogleMapsExample = 1.552795031055901

const TripoMap: React.FunctionComponent<TripoMapProps> = ({
    locations,
    label,
    width,
    height,
    onMapHeightSet,
    googleMapsApiKey
}) => {
    const [availableWidth, setAvailableWidth] = useState(0)
    const div = useCallback((node) => {
        if (node !== null && node.getBoundingClientRect()) {
            setAvailableWidth(Math.ceil(node.getBoundingClientRect().width))
        }
    }, [])

    const definiteWidth = width || availableWidth
    const encodedUriLocations = locations.map(locationToUrlEncoded)
    const markersQueryParam = `${encodedUriLocations.reduce(
        (acc, curr) => `${acc}&markers=color:0x937FB7%7C${curr}`,
        '&markers=color:0x937FB7%7C'
    )}`

    const ratioWidth = definiteWidth || (height && Math.ceil(height * heightRatioFromGoogleMapsExample))
    const ratioHeight = height || Math.ceil(definiteWidth / heightRatioFromGoogleMapsExample)
    const sizeQueryParam = `size=${ratioWidth}x${ratioHeight}`

    useEffect(() => {
        if (onMapHeightSet) onMapHeightSet(ratioHeight)
    }, [availableWidth, onMapHeightSet, ratioHeight])

    const googleMapsAddressSrc = `https://maps.googleapis.com/maps/api/staticmap?${sizeQueryParam}${markersQueryParam}&maptype=roadmap&zoom=15&key=${googleMapsApiKey}`

    return (
        <div ref={div} style={{ width: '100%' }}>
            {definiteWidth && <Image src={googleMapsAddressSrc} alt={label || 'address_map'} preview={false}></Image>}
        </div>
    )
}

const locationToUrlEncoded = (location: TripoMapModel): string => {
    return location.address
        ? encodeURIComponent(location.address)
        : encodeURIComponent(`${location.latitude},${location.longitude}`)
}

export default TripoMap
