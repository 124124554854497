import { Config, FilterValues, OptionSelect } from '../../type'

export const addFilterValues = (
    prevState: FilterValues,
    id: string,
    value: unknown,
    autocompleteFilters?: Config[],
    newValue?: unknown
): FilterValues => {
    let newState = { ...prevState, [id]: value }
    //if it's an autocomplete filter all groups of inputs should be filled when add a new value
    if (autocompleteFilters?.length && newValue) {
        const filtersToAutocomplete = autocompleteFilters.filter((filter) => filter.id !== id)

        filtersToAutocomplete.map((filter) => {
            const { id } = filter
            const newIdValues = prevState[id] ? (prevState[id] as OptionSelect[]) : []

            if (!newIdValues.length) {
                newState = { ...newState, [id]: value, [id]: [newValue] }
                return
            }

            const castNewValue = newValue as OptionSelect
            const newValueAlreadyExist = newIdValues.some((option) => option.value === castNewValue.value)
            if (!newValueAlreadyExist) newState = { ...newState, [id]: value, [id]: [...newIdValues, castNewValue] }
            return
        })
    }

    return newState
}

export const cleanFilterValues = (prevState: FilterValues, cleanId: string, value: string): FilterValues => {
    const currentItem = prevState[cleanId] as OptionSelect[]
    if (!currentItem) return prevState
    const cleanItem = currentItem.filter((item) => item.value !== value)
    return { ...prevState, [cleanId]: cleanItem.length ? cleanItem : undefined }
}
