import { OptionSelect, RangeType } from './type'

const formatHour = (hour: string): string => {
    let formatedHour = hour
    if (formatedHour.length === 1) formatedHour = '0' + formatedHour
    return formatedHour
}

const formatMinutes = (minutes: string): string => {
    let formatedMinutes = '00'
    if (minutes) formatedMinutes = `${60 * (+minutes / 10)}`
    return formatedMinutes
}

export const formatNumberToTime = (timeNumber: number): string => {
    if (!timeNumber) return '00:00'
    const [hour, minute] = `${timeNumber}`.split('.')
    const formatedHour = formatHour(hour)
    const formatedMinutes = formatMinutes(minute)
    return `${formatedHour}:${formatedMinutes}`
}
export const formatToCurrency = (value: number): string => `${value}€ `

export const formatObjectToString = (option: OptionSelect, isLast: boolean): string => {
    const { value } = option
    return !isLast ? `${value}#` : `${value}`
}

const handleSelectLabel = (value: unknown): string => {
    if (value && typeof value === 'object') {
        const selectOption = value as OptionSelect
        return selectOption.label
    }
    return `${value}`
}

export const formatLabel = (label: string, value: unknown, type: string, rangeType?: string): string => {
    if (Array.isArray(value) && type === 'range') {
        let formatedLabel = label

        value.forEach((item) => {
            if (rangeType) {
                if (rangeType === RangeType.time) formatedLabel = formatedLabel.replace('{}', formatNumberToTime(item))
                if (rangeType === RangeType.currency)
                    formatedLabel = formatedLabel.replace('{}', formatToCurrency(item))
            } else formatedLabel = formatedLabel.replace('{}', item)
        })

        return formatedLabel
    }
    if (type === 'select') label = label.replace('{}', handleSelectLabel(value))

    return label.replace('{}', `${value}`)
}
