import { Col, Typography, Row } from 'antd'
import React, { Fragment, FunctionComponent, useContext } from 'react'
import FiltersUIContext from '../../FiltersContext'
import { FilterGroup, FiltersGroups, FilterValueApplied, Response } from '../../type'
import InputSwitch from '../InputSwitch'

const { Text } = Typography

interface FiltersProps {
    filtersConfigs: FiltersGroups
    getSelectSearchAsyncOptions: (url: string, searchValue: string) => Promise<Response>
}

const Filters: FunctionComponent<FiltersProps> = ({ filtersConfigs, getSelectSearchAsyncOptions }) => {
    const { filtersValues, onFilterChange } = useContext(FiltersUIContext)

    return (
        <Row className="unified-filters-body">
            {filtersConfigs.map(({ group, filters }) => {
                const isFilterStyled = filters.some((filter) => filter.style)
                // remove general filters
                const clearFilters = filters?.filter((filter) => filter.filterGroup !== FilterGroup.general)
                return (
                    <Fragment key={group}>
                        <Col span={24} className="filters-group-container">
                            {group !== FilterGroup.general && <Text strong>{group.toUpperCase()}</Text>}
                        </Col>
                        <Col span={24} className={`filters-group-container ${isFilterStyled ? 'styledFilter' : ''}`}>
                            {clearFilters.map(
                                ({ id, path, tag, type, category, logic, rangeType, filterGroup, group, ...props }) => (
                                    <Col
                                        span={`${isFilterStyled ? 3 : 24}`}
                                        className="filter-input-container"
                                        key={id}
                                    >
                                        <InputSwitch
                                            {...props}
                                            type={type}
                                            rangeType={rangeType}
                                            currentValue={filtersValues?.[id]}
                                            onChange={(value: FilterValueApplied, newValue: unknown) => {
                                                onFilterChange?.({
                                                    id,
                                                    path,
                                                    tag,
                                                    type,
                                                    category,
                                                    logic,
                                                    value,
                                                    newValue,
                                                    rangeType,
                                                    filterGroup,
                                                    group
                                                })
                                            }}
                                            getSelectSearchAsyncOptions={getSelectSearchAsyncOptions}
                                        />
                                    </Col>
                                )
                            )}
                        </Col>
                    </Fragment>
                )
            })}
        </Row>
    )
}

export default Filters
