import React, { Fragment } from 'react'
import { computeDateDiffNextDay, TripoDateTime } from '../../utils/date'
import { Badge, Typography } from 'antd'
import { TripoDate, TripoDateConfig } from './TripoDate'

const { Text } = Typography

interface TripoDateProps {
    dateStart: Date
    dateEnd: Date
    config: TripoDateConfig
}

const TripoDatesSegment: React.FunctionComponent<TripoDateProps> = ({ dateStart, dateEnd }) => {
    const countDays = computeDateDiffNextDay(dateStart, dateEnd, 'days')
    const count = countDays > 0 ? `+${countDays}` : 0
    const defaultContext = { language: 'es', timeZone: 'Europe/Madrid' }

    return (
        <Fragment>
            <Badge count={count} offset={[15, 0]} status="default">
                <Text strong>
                    <TripoDate
                        date={dateStart}
                        format={TripoDateTime.TIME_24_SIMPLE}
                        config={defaultContext}
                    ></TripoDate>
                    <Text strong> - </Text>
                    <TripoDate date={dateEnd} format={TripoDateTime.TIME_24_SIMPLE} config={defaultContext}></TripoDate>
                </Text>
            </Badge>
        </Fragment>
    )
}

export default TripoDatesSegment
