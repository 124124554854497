import React, { FunctionComponent, useContext } from 'react'
import { Tag } from 'antd'
import FiltersUIContext from '../FiltersContext'
import { formatLabel } from '../utils'
import { ActiveTags } from '../type'

const CustomTag: FunctionComponent<ActiveTags> = ({ id, category, tag, value, type, rangeType, filterGroup }) => {
    const { onTagClose, onTagClick } = useContext(FiltersUIContext)
    return (
        <Tag
            color="blue"
            key={id}
            closable
            onClose={(e) => {
                e.preventDefault()
                onTagClose?.(id, type, filterGroup)
            }}
            onClick={() => {
                onTagClick?.(category)
            }}
        >
            {formatLabel(tag, value, type, rangeType)}
        </Tag>
    )
}

export default CustomTag
