import { FormMode, ConfirmationModalData } from './types'

export const shouldShowConfirmationModal = (mode?: string, confirmationModalData?: ConfirmationModalData): boolean => {
    const { create, update } = confirmationModalData?.trigger || {
        create: false,
        update: false
    }

    const triggerOnCreate = (create && mode === FormMode.create) || false
    const triggerOnUpdate = (update && mode === FormMode.update) || false

    return triggerOnCreate || triggerOnUpdate
}

export const formatConfirmationModalFields = (
    formData: Record<string, unknown> | null,
    validationFields?: string[]
): {
    field: string
    value: string
}[] => {
    if (!formData || !validationFields) return []
    return validationFields.map((field) => ({
        field,
        value: JSON.stringify(formData[field])
    }))
}
