import React from 'react'
import { OrderConfig } from './OrderInputs/type'
import { Config, ActiveTags, OnFilterChangeValue, FilterValues, Category, AppliedOrder } from './type'

type ContextState = {
    config?: Config[]
    tripType?: string
    setTripType?: (data: string) => void
    filtersValues?: FilterValues
    onFilterChange?: (data: OnFilterChangeValue) => void
    categories?: Category[]
    activeTab?: Category
    onTabChange?: (category: Category) => void
    activeTags?: ActiveTags[]
    onTagClose?: (filterId: string, type: string, filterGroup?: string) => void
    onTagClick?: (category: Category) => void
    onTabClick?: (category: Category) => void
    orderConfig?: OrderConfig
    onOrderChange?: (data: AppliedOrder) => void
    clearAllFilters?: () => void
}

const Context = React.createContext<ContextState>({})

export default Context
