import React, { Fragment, FunctionComponent } from 'react'
import { Switch, Typography, Row, Col } from 'antd'
const { Paragraph, Text } = Typography
interface SwitchInputProps {
    label?: string
    strongLabel?: boolean
    description?: string
    value?: boolean | undefined
    onChange?: (value: boolean, event: MouseEvent) => void
}

const CheckboxInput: FunctionComponent<SwitchInputProps> = ({ label, strongLabel, description, value, onChange }) => {
    return (
        <Fragment>
            <Row style={{ justifyContent: 'space-between' }}>
                {label && (
                    <Col>
                        <Text strong={strongLabel}>{label}</Text>
                        {description && <Paragraph>{description}</Paragraph>}
                    </Col>
                )}
                <Col>
                    <Switch
                        defaultChecked={value}
                        onChange={(newValue, event) => {
                            if (onChange) {
                                onChange(newValue, event)
                            }
                        }}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

export default CheckboxInput
