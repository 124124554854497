import React, { FunctionComponent } from 'react'
import { Col, Row } from 'antd'
import FareTableHeader from './FareTableHeader'
import FareTableContent from './FareTableContent'
import { FaresData, SelectedFare, FaresColSize } from './interface'

type FareTableProps = {
    data: FaresData
    selectedFare?: SelectedFare
    setSelectedFare?: (value: SelectedFare) => void
    colSizeDictionary?: FaresColSize
    readOnly?: boolean
}

const defaultColSizeDictionary = {
    col1: 7,
    col2: 2,
    col3: 2,
    col4: 3,
    col5: 3,
    col6: 3,
    col7: 4
}

const FareTable: FunctionComponent<FareTableProps> = ({
    data,
    selectedFare,
    setSelectedFare,
    readOnly = false,
    colSizeDictionary = defaultColSizeDictionary
}): JSX.Element => {
    const getClassName = (useOptionalClass: boolean) => {
        const classes = ['fares-table-row']
        const optionalClass = 'fixed-height'

        if (useOptionalClass) {
            classes.push(optionalClass)
        }

        return classes.join(' ')
    }
    return (
        <Row className={getClassName(!readOnly)} align="top">
            <Col span={24} className="fare-scrollable-container">
                <FareTableHeader data={data.header} colSizeDictionary={colSizeDictionary} />
            </Col>
            <Col span={24} className="fares-fixed-content-size fare-scrollable-container">
                <FareTableContent
                    colSizeDictionary={colSizeDictionary}
                    data={data.content}
                    selectedFare={selectedFare}
                    setSelectedFare={setSelectedFare}
                />
            </Col>
        </Row>
    )
}

export default FareTable
