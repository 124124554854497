import { FunctionComponent } from 'react'

// Implemented Inputs

export enum InputTypes {
    text = 'text',
    uniqueText = 'uniqueText',
    select = 'select',
    password = 'password',
    number = 'number',
    image = 'image',
    checkbox = 'checkbox',
    datepicker = 'datepicker',
    timepicker = 'timepicler',
    switch = 'switch',
    textArea = 'textArea',
    wysiwyg = 'wysiwyg'
}

//  Form Configuration
export interface FormData {
    section: string
    dynamicInputs?: DynamicInputsData
    inputs: InputAndFormData[]
}

type InputAndFormData = FormData | InputData

export interface InputData {
    type: string
    name: string
    label: {
        text: string
        strong?: boolean
    }
    dependencies?: string[]
    rules?: {
        required?: boolean
        message: string
        optionalOnEdit?: boolean
    }[]
    validatorRule?: {
        type: string
        fieldName: string
        message: string
    }
    asyncValidatorRule?: {
        validatingMessage: string
        validationUrl: string
        validateTrigger: string
    }
    optionsUri?: string
    width?: number | string
    addQuery?: Query[]
    url?: string
    note?: string
    readOnly?: {
        onCreate: boolean
        onUpdate: boolean
    }
    disabled?: {
        onCreate: boolean
        onUpdate: boolean
    }
    textArea?: {
        showCount?: boolean
        maxLength?: number
    }
    isVisible?: 'block' | 'none'
    inputRelated?: string
    colSpan?: number | 'auto'
}

export interface DynamicInputsData {
    // disabled for items that already exist at initialValues
    disableRemove?: boolean
    disableInputs?: string[]
    groupName: string
    buttonLabels: {
        add: string
        remove: string
    }
}

//  Dynamic Inputs Specific Configuration

export type DynamicInputOptions = {
    disableRemoveBtnByGroup?: DisableRemoveBtnByGroup
    disableInputByGroup?: DisableInputByGroup
}

type DisableRemoveBtnByGroup = Record<string, number>
type DisableInputByGroup = Record<string, { name: string[]; index: number }>

// Injected Helpers

export interface InputsInjectedHelperProps {
    isUpdate?: boolean
    getSelectOptions?: GetSelectOptions
    uploadAsset?: UploadAsset
    inputValidator?: InputValidator
    wysiwygFactory?: WysiwygFactory
}

export type Query = {
    inputNameRelated: string
    queryName: string
    mode: string
}
export interface Option {
    label: string
    value: string
    tooltip?: string
}

export type GetSelectOptions = (url: string) => Promise<{ data?: Option[]; error?: boolean }>

export type getIfFormItemIsVisible = (url: string, id: string) => Promise<{ errorMessage: string; enabled: boolean }>

export interface ImageSrc {
    url: string
}

export type UploadAsset = (file: unknown) => Promise<{ data?: ImageSrc; error?: boolean }>

export type InputValidator = (url: string, value: unknown) => Promise<{ validValue: boolean; errorMessage: string }>

export type WysiwygInstance = {
    component: FunctionComponent<WysiwygProps>
    onChangeHandler: (payload: unknown) => string | undefined
}

export type WysiwygFactory = () => WysiwygInstance

export interface WysiwygProps {
    onChange?: (payload: unknown) => void
    value?: string
}
