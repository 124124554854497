import React, { FunctionComponent, Fragment } from 'react'

import { Input } from 'antd'
const { Password } = Input
import { InputTypes, InputData, DynamicInputOptions } from '../../../types'
import { InputsHelperProps } from '../../interface'
import { SelectInput } from '../../SharedInputs'
import SwitchInput from './SwitchInput'
import TextInput from './TextInput'

interface PlainInputsSwitchProps extends InputsHelperProps {
    type: string
    data: InputData
    groupName: string
    fieldKey: number
    dynamicInputOptions?: DynamicInputOptions
}

const PlainInputsSwitch: FunctionComponent<PlainInputsSwitchProps> = ({ type, data, ...props }) => {
    switch (type) {
        case InputTypes.text:
            return <TextInput name={data.name} {...props} />
        case InputTypes.password:
            return <Password {...props} />
        case InputTypes.switch:
            return <SwitchInput {...props} />
        case InputTypes.select:
            return <SelectInput {...props} data={data} />
        default:
            return <Fragment></Fragment>
    }
}

export default PlainInputsSwitch
