import React, { Fragment, FunctionComponent, useState, useEffect, useContext } from 'react'
import { Tabs } from 'antd'

import FiltersUIContext from '../FiltersContext'

const { TabPane } = Tabs

const FiltersCategoriesTabs: FunctionComponent = () => {
    const { config, categories, activeTab, onTabChange, onTabClick } = useContext(FiltersUIContext)
    const [tabCategories, setTabCategory] = useState<string[]>([])

    useEffect(() => {
        const firstCategory = categories?.[0]
        if (!config || !categories || !firstCategory) {
            setTabCategory([])
            return
        }
        // TEMPORARILY: Remove general filters in SBT until merge feature in ABT & SBT
        const generalCategory = 'general'
        setTabCategory(categories.filter((category) => firstCategory !== category && category !== generalCategory))
    }, [config, categories])

    if (!tabCategories.length) {
        return <Fragment></Fragment>
    }

    return (
        <Tabs
            className="tab-categories-container"
            defaultActiveKey="initial"
            activeKey={activeTab}
            onChange={onTabChange}
            onTabClick={onTabClick}
        >
            <TabPane tab="Mas Filtros:" key="first" />
            <TabPane key="initial" />
            {tabCategories.map((tabCategory) => (
                <TabPane tab={tabCategory} key={tabCategory} />
            ))}
        </Tabs>
    )
}

export default FiltersCategoriesTabs
