import React, { FunctionComponent, Fragment } from 'react'
import { Row, Col, Typography } from 'antd'

const { Title, Text } = Typography

type FareConditionsType = {
    short_name: string
    message: string
}[]

type FareConditionsProps = {
    conditions?: FareConditionsType
    selectedFareSolutionId: string
}
const FareConditions: FunctionComponent<FareConditionsProps> = ({ conditions }) => {
    const formatTitle = (title: string): string => {
        if (typeof title !== 'string') return title
        const trimmedTitle = title.trim()
        const prefix = '- '
        const regex = /- /

        const match = trimmedTitle.match(regex)
        if (match && match.index === 0) {
            return trimmedTitle.replace(prefix, '')
        }

        return trimmedTitle
    }

    if (!conditions?.length) return <Fragment></Fragment>
    return (
        <Row gutter={[0, 20]} className="fares-conditions">
            {conditions?.map(({ short_name, message }) => (
                <Col key={short_name} span={24}>
                    <Title level={5}>{formatTitle(short_name)}</Title>
                    <Text>{message}</Text>
                </Col>
            ))}
        </Row>
    )
}

export default FareConditions
