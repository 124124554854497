import React from 'react'

enum TripoIconEnum {
    map = 'icon-map-marker-alt-solid',
    fare = 'icon-ticket-alt-solid',
    bag = 'icon-suitcase-rolling-solid-1',
    suitcase0 = 'icon-suitcase0',
    suitcase1 = 'icon-suitcase1',
    suitcase2 = 'icon-suitcase2',
    suitcase3 = 'icon-suitcase3',
    person = 'icon-user-solid',
    cutlery = 'icon-utensils-solid',
    bed = 'icon-bed-solid',
    x = 'icon-times-solid',
    check = 'icon-check-solid',
    ban = 'icon-ban-solid',
    ticket = 'icon-ticket-alt-solid',
    gears = 'icon-gears',
    frost = 'icon-snowflake-solid',
    door = 'icon-car-door',
    car = 'icon-car-solid',
    shield = 'icon-shield-alt-solid',
    creditCard = 'icon-credit-card-regular',
    wheelProtection = 'icon-car-crash-solid',
    info = 'icon-info-circle-solid'
}

enum TripoIconStatusEnum {
    warning = 'warning',
    danger = 'danger'
}

interface TripoIconProp {
    icon: TripoIconEnum
    style?: React.CSSProperties
    color?: TripoIconStatusEnum
}

const TripoIcon: React.FunctionComponent<TripoIconProp> = ({ icon, style = { marginRight: '2px' }, color }) => {
    return <i className={`${TripoIconEnum[icon]}  ant-typography ant-typography-${color}`} style={{ ...style }}></i>
}
export { TripoIcon, TripoIconStatusEnum, TripoIconEnum }
