export enum FormMode {
    create = 'create',
    update = 'update'
}

export interface ConfirmationModalData {
    trigger: {
        create?: true
        update?: true
    }
    okText: string
    cancelText: string
    message: string
    validationFields: string[]
}
