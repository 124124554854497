import { Col, Row } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import FiltersUIContext from '../../FiltersContext'
import { Config, FilterValueApplied, Response } from '../../type'
import InputSwitch from '../InputSwitch'

interface FiltersProps {
    filtersConfigs: Config[]
    getSelectSearchAsyncOptions: (url: string, searchValue: string) => Promise<Response>
}

const Filters: FunctionComponent<FiltersProps> = ({ filtersConfigs, getSelectSearchAsyncOptions }) => {
    const { filtersValues, onFilterChange } = useContext(FiltersUIContext)
    return (
        <Row align="middle">
            {filtersConfigs.map(({ id, path, tag, type, category, logic, rangeType, filterGroup, group, ...props }) => (
                <Col span={4} key={id}>
                    <InputSwitch
                        {...props}
                        type={type}
                        rangeType={rangeType}
                        currentValue={filtersValues?.[id]}
                        onChange={(value: FilterValueApplied) =>
                            onFilterChange?.({
                                id,
                                path,
                                tag,
                                type,
                                category,
                                logic,
                                value,
                                rangeType,
                                filterGroup,
                                group
                            })
                        }
                        getSelectSearchAsyncOptions={getSelectSearchAsyncOptions}
                    />
                </Col>
            ))}
        </Row>
    )
}

export default Filters
