export type Category = string
export type Group = string
export interface Option {
    label: string
    value: string
}

export type OptionsGroup = {
    groupName?: string
    options: Option[]
}
export interface Range {
    min: number
    max: number
}
export interface Response {
    error?: boolean
    data?: OptionsGroup[] | OptionsGroup
}
export interface Config {
    id: string
    path: string
    type: string
    label: string
    placeholder?: string
    tag: string
    category: Category
    group?: Group
    step?: number
    range?: Range
    options?: Option[]
    span?: number | 'none'
    logic?: string
    mode?: ValidModes
    autocompletedById?: string
    rangeType?: string
    filterType?: string
    style?: boolean
    filterGroup?: string
}
export enum TripType {
    ida = 'ida',
    vuelta = 'vuelta',
    multi = 'multi',
    general = 'general'
}

export enum FilterType {
    bySolution = 'bySolution', // Only 1 filter input by flight
    byJourney = 'byJourney' // filters by journeys
}
export enum FilterGroup {
    general = 'general'
}

export type CurrentInputsData = { tripType: string; fieldsAmount: number }

export enum ValidModes {
    multiple = 'multiple',
    tags = 'tags'
}
export interface AppliedFilters {
    id: string
    type: string
    path: string
    value: unknown
    logic?: string
    rangeType?: string
    filterGroup?: string
    group?: string
}
export interface ActiveTags {
    id: string
    value: unknown
    category: Category
    type: string
    tag: string
    rangeType?: string
    filterGroup?: string
}
export interface OnFilterChangeValue {
    rangeType?: string
    id: string
    path: string
    value: unknown
    newValue?: unknown
    category: Category
    tag: string
    type: string
    logic?: string
    mode?: ValidModes
    filterGroup?: string
    group?: string
}

export type FilterValueApplied = boolean | string | string[] | number[] | OptionSelect[]
export interface AppliedOrder {
    id: string
    path: string
    value: unknown
    type: string
}
export interface FilterValues {
    [id: string]: unknown
}
export interface GroupedFilters {
    [group: string]: Config[]
}

export type GroupedFiltersList = [string, Config[]][]

export type OptionSelect = {
    label: string
    value: string
    key: string
    disabled: boolean
}

export type TripTypeGroup = {
    departure: string
    return: string
    multiDestination: string
}

export enum RangeType {
    time = 'time',
    currency = 'currency'
}
export interface FiltersGroup {
    group: string
    filters: Config[]
}
export type FiltersGroups = FiltersGroup[]
