import React, { FunctionComponent, Fragment } from 'react'
import { FaresContentCols } from '../interface'
import FareTableIcon from './FareTableIcon'

type FareTableItemsProps = { data: (FaresContentCols | null)[] }

const getColorByStatus = (iconName?: string) => {
    switch (iconName) {
        case 'check':
            return 'primary-color'
        default:
            return
    }
}

const getClassName = (iconName?: string) => {
    const classes = ['fares-icon-size']
    const optionalClass = getColorByStatus(iconName)

    if (optionalClass) {
        classes.push(optionalClass)
    }

    return classes.join(' ')
}

const FareTableItems: FunctionComponent<FareTableItemsProps> = ({ data }) => (
    <Fragment>
        {data.map((item, ind) => (
            <div key={ind}>
                <FareTableIcon className={getClassName(item?.icon)} iconName={item?.icon} />
            </div>
        ))}
    </Fragment>
)

export default FareTableItems
