import React, { Fragment, FunctionComponent } from 'react'
import { Checkbox, Slider, Input } from 'antd'
import { Option, Range, ValidModes, Response, RangeType } from '../type'
import { formatNumberToTime } from '../utils'
import FilterSelect from './CommonComponents/FilterSelect'

interface InputSwitchProps {
    type: string
    label: string
    placeholder?: string
    step?: number
    range?: Range
    options?: Option[]
    url?: string
    mode?: ValidModes
    currentValue: unknown
    onChange: (value: unknown, newValue?: unknown) => void
    getSelectSearchAsyncOptions: (url: string, searchValue: string) => Promise<Response>
    rangeType?: string
}

const InputSwitch: FunctionComponent<InputSwitchProps> = ({
    type,
    label,
    placeholder,
    options,
    url,
    step,
    range,
    mode,
    currentValue,
    onChange,
    getSelectSearchAsyncOptions,
    rangeType
}) => {
    const castAsCheckboxValue = (value: unknown): boolean => {
        return !!value
    }

    const castAsRangeValue = (value: unknown): undefined | [number, number] => {
        if (Array.isArray(value) && value.length === 2) {
            return value as [number, number]
        }
        return
    }

    const castAsStringValue = (value: unknown): string | undefined => {
        if (typeof value === 'string') return value as string
        return
    }

    const tipFormat = (rangeType: string | undefined) => (rangeType === RangeType.time ? formatNumberToTime : undefined)

    switch (type) {
        case 'boolean':
            return (
                <Checkbox
                    checked={castAsCheckboxValue(currentValue)}
                    onChange={(event) => {
                        onChange(event.target.checked)
                    }}
                >
                    {label}
                </Checkbox>
            )
        case 'range':
            let defaultValue: [number, number] | undefined
            if (range) {
                defaultValue = [range?.min, range?.max]
            }
            return (
                <div className="range-filter filter-input">
                    <label>{label}</label>
                    <Slider
                        onChange={onChange}
                        range={{ draggableTrack: true }}
                        min={range?.min}
                        max={range?.max}
                        defaultValue={defaultValue}
                        step={step}
                        tipFormatter={tipFormat(rangeType)}
                        value={castAsRangeValue(currentValue)}
                    />
                </div>
            )
        case 'select':
            return (
                <div className="select-filter filter-input">
                    <label>{label}</label>
                    <FilterSelect
                        onChange={onChange}
                        options={options}
                        url={url}
                        currentValue={currentValue}
                        mode={mode}
                        getSelectSearchAsyncOptions={getSelectSearchAsyncOptions}
                        placeholder={placeholder}
                    />
                </div>
            )

        case 'text':
            return (
                <Fragment>
                    {label}
                    <Input
                        allowClear
                        onChange={(event) => onChange(event.target.value)}
                        placeholder={placeholder}
                        value={castAsStringValue(currentValue)}
                    />
                </Fragment>
            )
        default:
            return <Fragment></Fragment>
    }
}

export default InputSwitch
