import Cookies, { CookieSetOptions } from 'universal-cookie'

export class AuthenticationTokenHandler {
    private cookies = new Cookies()
    private cookieName = 'x-triporate-token'

    public getToken(): string | null {
        return this.cookies.get(this.cookieName)
    }

    public setToken(token: string): void {
        const expires = new Date(2030, 1, 1)
        this.cookies.set(this.cookieName, token, this.getOptions(expires))
    }

    public removeToken(): void {
        this.cookies.remove(this.cookieName, this.getOptions())
    }

    private getDomain() {
        const currentHost = window.location.host

        if (currentHost.includes('localhost')) {
            return 'localhost'
        }

        return currentHost.split('.').slice(1).join('.')
    }

    private getOptions(expires?: Date): CookieSetOptions {
        return {
            path: '/',
            domain: this.getDomain(),
            expires
        }
    }
}
