import React, { FunctionComponent } from 'react'
import { Input } from 'antd'

const { TextArea } = Input

import { InputData } from '../../../types'
import FormItem from './FormItem'

interface TextAreaInputProps {
    data: InputData
}

const TextAreaInput: FunctionComponent<TextAreaInputProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <TextArea showCount={data.textArea?.showCount} maxLength={data.textArea?.maxLength} />
        </FormItem>
    )
}

export default TextAreaInput
