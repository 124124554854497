import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { Select } from 'antd'
import { Option } from '../type'

interface InputSwitchProps {
    type: string
    options?: Option[]
    label: string
    direction?: string
    onChange: (value: unknown) => void
}

const InputSwitch: FunctionComponent<InputSwitchProps> = ({ type, options, label, onChange }) => {
    const [selected, setSelected] = useState<string>()

    useEffect(() => {
        setSelected(options?.[0].value)
    }, [options])

    switch (type) {
        case 'minor':
            return (
                <div className="select-order">
                    <label>{label}</label>
                    <Select
                        onChange={(value) => {
                            setSelected(value)
                            return onChange(value)
                        }}
                        options={options}
                        value={selected}
                        defaultValue={options?.[0].value}
                    />
                </div>
            )

        default:
            return <Fragment></Fragment>
    }
}

export default InputSwitch
