import React, { FunctionComponent, Fragment } from 'react'
import { Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
type ItemLabelProps = {
    labelData: {
        text: string
        icon?: string
    }
    note?: string
}

const ItemLabel: FunctionComponent<ItemLabelProps> = ({ labelData, note }) => (
    <Fragment>
        <Text>{labelData.text}</Text>
        {note && (
            <span className="label-note">
                <Tooltip title={note} placement="right">
                    <span>
                        <QuestionCircleOutlined />
                    </span>
                </Tooltip>
            </span>
        )}
    </Fragment>
)

export default ItemLabel
