import { Button, Col, Typography } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import FiltersUIContext from '../FiltersContext'
import { OptionSelect } from '../type'
import CustomTag from './CustomTag'

const { Text } = Typography
interface TagsProps {
    filterBtn: string
}

const Tags: FunctionComponent<TagsProps> = ({ filterBtn }) => {
    const { activeTags, clearAllFilters } = useContext(FiltersUIContext)
    const hasActiveTags = activeTags && activeTags.length > 0
    return (
        <div className="tags-container">
            {hasActiveTags && (
                <Col className="button-div">
                    <Button size="small" onClick={clearAllFilters}>
                        <Text>{filterBtn}</Text>
                    </Button>
                </Col>
            )}
            {activeTags?.map((tagProp) => {
                const { value, id, type, rangeType, filterGroup } = tagProp
                const isSelect = type === 'select' && Array.isArray(value)
                if (!isSelect) return <CustomTag key={id} {...tagProp} />
                else {
                    const castedValues = value as OptionSelect[]
                    return castedValues.map((item) => {
                        const tempId = `${id}-${item.value}-list`
                        return (
                            <CustomTag
                                key={tempId}
                                {...tagProp}
                                value={item}
                                id={tempId}
                                rangeType={rangeType}
                                filterGroup={filterGroup}
                            />
                        )
                    })
                }
            })}
        </div>
    )
}

export default Tags
