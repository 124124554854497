import { Config } from '../../type'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'

import FiltersUIContext from '../../FiltersContext'
import { Response, FilterGroup } from '../../type'
import Filters from './Filters'

interface GeneralFiltersProps {
    getSelectSearchAsyncOptions: (url: string, searchValue: string) => Promise<Response>
}

const GeneralFilters: FunctionComponent<GeneralFiltersProps> = ({ getSelectSearchAsyncOptions }) => {
    const { config } = useContext(FiltersUIContext)
    const [groupedFilters, setGropued] = useState<Config[]>([])
    useEffect(() => {
        const generalFilters = config?.filter((filter) => filter.filterGroup === FilterGroup.general)
        if (generalFilters?.length) setGropued(generalFilters)
        else setGropued([])
    }, [config])

    return <Filters filtersConfigs={groupedFilters} getSelectSearchAsyncOptions={getSelectSearchAsyncOptions} />
}

export default GeneralFilters
