import { TripoCardProps } from './TripoCard'

const useTripoCardAdditionalClassProps = (props: TripoCardProps): string => {
    let additionalClassNames = ''
    if (props.shadow) additionalClassNames += `${additionalClassNames} tripo-ant-card-shadowed`
    if (props.shadow === 'horizontal')
        additionalClassNames += `${additionalClassNames} tripo-ant-card-shadowed-horizontal`
    if (props.shadow === 'vertical') additionalClassNames += `${additionalClassNames} tripo-ant-card-shadowed-vertical`

    if (props.background === 'transparent')
        additionalClassNames += `${additionalClassNames} tripo-ant-card-background-transparent`

    if (props.fixed) additionalClassNames += `${additionalClassNames} tripo-ant-card-fixed`

    return `${props.className || ''} ${additionalClassNames}`
}

export default useTripoCardAdditionalClassProps
