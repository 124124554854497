import { notification } from 'antd'

interface NotificationOptions {
    message: string
    description: string
    duration?: number
}

type NotificationHandler = (options: NotificationOptions) => void

export const usePanelNotification = (): {
    successNotification: NotificationHandler
    errorNotification: NotificationHandler
} => {
    const successNotification: NotificationHandler = ({ message, description, duration = 4 }) =>
        notification.success({ message, description, duration })

    const errorNotification: NotificationHandler = ({ message, description, duration = 0 }) =>
        notification.error({ duration, message, description })

    return { successNotification, errorNotification }
}
