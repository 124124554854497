import React from 'react'
import { Card } from 'antd'
import { CardProps } from 'antd/lib/card'
import useTripoCardAdditionalClassProps from './useTripoCardAdditionalClassProps'

interface TripoCardProps extends CardProps {
    background?: 'transparent' | 'default'
    shadow?: boolean | 'horizontal' | 'vertical'
    fixed?: boolean
}

interface TripoCardInterface extends React.FunctionComponent<TripoCardProps> {
    Grid: typeof Card.Grid
    Meta: typeof Card.Meta
}

const TripoCard: TripoCardInterface = ({
    shadow = false,
    background = 'default',
    fixed = false,
    ...rest
}: TripoCardProps) => {
    const handledClassName = useTripoCardAdditionalClassProps({
        shadow,
        background,
        fixed,
        className: rest.className
    })
    return <Card {...rest} className={handledClassName} />
}

TripoCard.Meta = Card.Meta
TripoCard.Grid = Card.Grid

export type { TripoCardProps }
export { TripoCard }
