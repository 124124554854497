import React, { FunctionComponent } from 'react'
import { Button } from 'antd'

type CreateHeaderButtonProps = {
    text?: string
    handleNavigationClick: (to: string) => void
}

const CreateHeaderButton: FunctionComponent<CreateHeaderButtonProps> = ({ text, handleNavigationClick }) => {
    return (
        <Button
            type="primary"
            onClick={() => {
                handleNavigationClick('/create')
            }}
        >
            {text}
        </Button>
    )
}

export default CreateHeaderButton
