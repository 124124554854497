import React, { FunctionComponent, useMemo, useRef, useState, useEffect } from 'react'
import { Select } from 'antd'
import { ValidModes, Option, Response, OptionSelect } from '../../type'
import debounce from 'lodash/debounce'

interface FilterSelectProps {
    placeholder?: string
    currentValue: unknown
    onChange: (value: unknown, newValue?: unknown) => void
    getSelectSearchAsyncOptions?: (url: string, searchValue: string) => Promise<Response>
    options?: Option[]
    url?: string
    mode?: ValidModes
}

const FilterSelect: FunctionComponent<FilterSelectProps> = ({
    mode,
    options: propsOptions,
    url,
    currentValue,
    placeholder,
    onChange,
    getSelectSearchAsyncOptions
}) => {
    const debounceTimeout = 800
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<Option[]>([])
    const fetchRef = useRef(0)

    const checkRegex = (data: Option[], regex: RegExp, regexToCompare?: RegExp) => {
        return data
            .filter((option) =>
                regexToCompare
                    ? !regexToCompare.test(option.label) && regex.test(option.label)
                    : regex.test(option.label)
            )
            .sort((a, b) => a.label.localeCompare(b.label))
    }

    const debounceFetcher = useMemo(() => {
        const orderOptions = (data: Option[], value: string) => {
            //check first caracters
            const regexFirstCheck = new RegExp(`^${value}`, 'i')

            //check coincidences by second word if exist
            const regexSecondCheck = new RegExp(`^[A-Za-z]\\w+\\s(${value})`, 'i')

            //first show coincidences by first word
            const mainCoincidences = checkRegex(data, regexFirstCheck)

            //coincidences by second word
            const restOptions = checkRegex(data, regexSecondCheck, regexFirstCheck)

            return mainCoincidences.concat(restOptions)
        }

        const loadOptions = (value: string) => {
            if (!getSelectSearchAsyncOptions || !url) return

            const MIN_TEXT_LENGTH = 2
            if (value.length < MIN_TEXT_LENGTH) return
            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setLoading(true)

            getSelectSearchAsyncOptions(url, `?q=${value}`).then(({ data }) => {
                if (fetchId !== fetchRef.current || !data) return

                if (!Array.isArray(data)) {
                    const optionsOrdered = orderOptions(data.options, value)

                    setOptions(optionsOrdered)
                    setLoading(false)
                }
            })
        }
        return debounce(loadOptions, debounceTimeout)
    }, [getSelectSearchAsyncOptions, debounceTimeout, url])

    useEffect(() => {
        if (propsOptions) setOptions(propsOptions)
    }, [propsOptions])

    return (
        <Select
            onSearch={url ? debounceFetcher : undefined}
            allowClear
            labelInValue
            showSearch
            optionFilterProp="label"
            onChange={(value) => {
                let newValue: OptionSelect
                if (mode === 'multiple') {
                    const values = value as OptionSelect[]
                    newValue = values[values.length - 1]
                    const castedCurrentValue = currentValue as OptionSelect[]
                    const isNew = castedCurrentValue ? values.length > castedCurrentValue.length : true

                    onChange(value, isNew ? newValue : undefined)
                }
                onChange(value)
            }}
            value={currentValue}
            mode={mode}
            loading={loading}
            options={options}
            placeholder={placeholder}
        />
    )
}

export default FilterSelect
