import React, { Fragment } from 'react'
import { Typography, Descriptions, Space } from 'antd'
import { DescriptionsProps } from 'antd/lib/descriptions'
import { TripoFeatureModel } from './TripoFeatureModel'

import { TripoIcon, TripoIconEnum } from '../../general'

const { Text } = Typography

interface TripoFeatureProps extends DescriptionsProps {
    features: TripoFeatureModel[]
}

const TripoFeatures: React.FunctionComponent<TripoFeatureProps> = ({ title, layout, column, features }) => {
    return (
        <Fragment>
            {features && (
                <Descriptions title={title} layout={layout} column={column}>
                    {features.map((feature, i) => DefinedFeatures(i, feature))}
                </Descriptions>
            )}
        </Fragment>
    )
}

interface Feature {
    icon: string
    description: string
    note?: string
}

const DefinedFeatures = (i: number, feature: Feature) => {
    const definedFeatures =
        (feature?.description?.includes(':') && feature?.description?.split(':')[1]) || !feature?.description
    return !definedFeatures ? (
        <Descriptions.Item key={i}>
            <Space size="small">
                <TripoIcon icon={TripoIconEnum[feature.icon]}></TripoIcon>
                <Text>{feature.description}</Text>
            </Space>
        </Descriptions.Item>
    ) : null
}

export default TripoFeatures
