import React, { FunctionComponent } from 'react'
import { Input } from 'antd'
import { InputData } from '../../../types'
import FormItem from './FormItem'
const { Password } = Input

interface PasswordInputProps {
    data: InputData
    isUpdate?: boolean
}

const PasswordInput: FunctionComponent<PasswordInputProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <Password />
        </FormItem>
    )
}

export default PasswordInput
