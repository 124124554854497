import { ActiveTags, Config, OptionSelect } from '../../type'

export const addNewActiveTags = (
    prevState: ActiveTags[],
    id: string,
    value: unknown,
    category: string,
    tag: string,
    type: string,
    autocompleteFilters?: Config[],
    newValue?: unknown,
    rangeType?: string,
    filterGroup?: string
): ActiveTags[] => {
    let updateTags = updateActiveTags(prevState, id, value, category, tag, type, rangeType, filterGroup)

    //if it's an autocomplete filter all groups of inputs should be filled when add a new value
    if (autocompleteFilters?.length && newValue) {
        const filtersToAutocomplete = autocompleteFilters.filter((filter) => filter.id !== id)

        filtersToAutocomplete.map((filter) => {
            const { id, tag } = filter
            let newIdValues: OptionSelect[] = []

            //get current values of this filter
            prevState.map((activeTag) => {
                if (activeTag.id === id) newIdValues = activeTag.value as OptionSelect[]
            })

            if (!newIdValues.length)
                updateTags = [...updateTags, { id: id, value: [newValue], category, tag: tag, type, rangeType }]
            else {
                const castNewValue = newValue as OptionSelect
                const newValueAlreadyExist = newIdValues.some((option) => option.value === castNewValue.value)
                if (!newValueAlreadyExist)
                    updateTags = updateActiveTags(
                        updateTags,
                        id,
                        [...newIdValues, newValue],
                        category,
                        tag,
                        type,
                        rangeType,
                        filterGroup
                    )
            }

            return
        })
    }

    return updateTags
}

const updateActiveTags = (
    prevState: ActiveTags[],
    id: string,
    value: unknown,
    category: string,
    tag: string,
    type: string,
    rangeType?: string,
    filterGroup?: string
): ActiveTags[] => {
    const included = prevState.some((item) => item.id === id)
    return !included
        ? [...prevState, { id, value, category, tag, type, rangeType, filterGroup }]
        : prevState.map((item) => {
              if (item.id === id) item.value = value
              return item
          })
}

export const cleanActiveTags = (prevState: ActiveTags[], cleanId: string, value: string): ActiveTags[] => {
    return prevState.reduce<ActiveTags[]>((newState, item) => {
        if (Array.isArray(item.value) && item.id === cleanId) {
            const itemValue = item.value as OptionSelect[]
            const cleanValue = itemValue.filter((i) => i.value !== value)
            if (cleanValue.length) newState.push({ ...item, value: cleanValue })
        } else newState.push({ ...item })
        return newState
    }, [])
}
