import { useEffect } from 'react'

export type MessageItem = {
    messageId: string
    messageHandler: (data: string) => void
}

export function useWindowMessagesHandler(items: MessageItem[]): void {
    useEffect(() => {
        const eventListenerType = 'message'
        const handleIframeContentMessage = (event: MessageEvent) => {
            items.find(({ messageId }) => event?.data?.includes?.(messageId))?.messageHandler?.(event.data)
        }

        window.addEventListener(eventListenerType, handleIframeContentMessage)

        return () => {
            window.removeEventListener(eventListenerType, handleIframeContentMessage)
        }
    }, [items])
}
