import React, { FunctionComponent, Fragment } from 'react'
import { Row, Col } from 'antd'
import { TripoCard } from '../../../data-display'
import { FormData, InputData, DynamicInputOptions } from '../types'
import { InputsHelperProps } from './interface'
import InputsGenerator from './InputsGenerator'
import DynamicInputs from './DynamicInputs'

interface FormInputsProps extends InputsHelperProps {
    formData: FormData[]
    lastInputUpdated?: Record<string, unknown>
    dynamicInputOptions?: DynamicInputOptions
}

function filterFormData(inputData: FormData['inputs']): InputData[] {
    if (Array.isArray(inputData)) {
        const filteredData = inputData.filter((item) => !('dynamicInputs' in item))
        return filteredData as InputData[]
    }
    return []
}

const FormInputs: FunctionComponent<FormInputsProps> = ({ formData, dynamicInputOptions, children, ...props }) => {
    return (
        <Fragment>
            {formData.map(({ section, inputs, dynamicInputs }, ind) => (
                <Col key={ind} span={24}>
                    <TripoCard title={section} className="nested-inputs-card">
                        {children}
                        <Row gutter={[24, 12]}>
                            {dynamicInputs ? (
                                <Col span={24}>
                                    <DynamicInputs
                                        {...props}
                                        dynamicInputOptions={dynamicInputOptions}
                                        dynamicInputs={dynamicInputs}
                                        data={inputs}
                                    />
                                </Col>
                            ) : (
                                <InputsGenerator {...props} data={filterFormData(inputs)} />
                            )}
                        </Row>
                    </TripoCard>
                </Col>
            ))}
        </Fragment>
    )
}

export default FormInputs
