import React, { FunctionComponent } from 'react'
import { EuroCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, LineOutlined } from '@ant-design/icons'

type FareTableIconProps = { className?: string; iconName?: string }

const FareTableIcon: FunctionComponent<FareTableIconProps> = ({ iconName, className }) => {
    switch (iconName) {
        case 'check':
            return <CheckCircleOutlined className={className} />
        case 'euro':
            return <EuroCircleOutlined className={className} />
        case 'negative':
            return <CloseCircleOutlined className={className} />
        default:
            return <LineOutlined />
    }
}

export default FareTableIcon
