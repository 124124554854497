import React, { Fragment, FunctionComponent, useContext } from 'react'
import { Row, Col, Typography } from 'antd'

import { Config, FilterValueApplied, Response } from '../../type'
import InputSwitch from '../InputSwitch'
import FiltersUIContext from '../../FiltersContext'

const { Text } = Typography

interface GroupedFiltersProps {
    groupedConfigs: [string, Config[]][]
    getSelectSearchAsyncOptions: (url: string, searchValue: string) => Promise<Response>
}

const GroupedFilters: FunctionComponent<GroupedFiltersProps> = ({ groupedConfigs, getSelectSearchAsyncOptions }) => {
    const { filtersValues, onFilterChange } = useContext(FiltersUIContext)

    const groupContainerClass = (length: number, index: number) => {
        const baseClass = ['group-container']
        if (length - 1 !== index) {
            baseClass.push('mr')
        }

        return baseClass.join(' ')
    }

    const getColSpan = (span?: Config['span']) => {
        if (span === 'none') return undefined
        if (typeof span === 'number') return span

        return 24
    }

    return (
        <Fragment>
            {groupedConfigs.map(([group, configs], i, arr) => (
                <Col className={groupContainerClass(arr.length, i)} key={group} span={10}>
                    <Row>
                        <Col span={24}>
                            <Text className="uppercase-label" strong>
                                {group}
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Row align="middle" gutter={[20, 0]}>
                                {configs.map(({ id, path, tag, type, category, logic, span, rangeType, ...props }) => (
                                    <Col key={id} span={getColSpan(span)}>
                                        <InputSwitch
                                            {...props}
                                            type={type}
                                            rangeType={rangeType}
                                            currentValue={filtersValues?.[id]}
                                            onChange={(value: FilterValueApplied) =>
                                                onFilterChange?.({
                                                    id,
                                                    path,
                                                    tag,
                                                    type,
                                                    category,
                                                    logic,
                                                    value,
                                                    rangeType
                                                })
                                            }
                                            getSelectSearchAsyncOptions={getSelectSearchAsyncOptions}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            ))}
        </Fragment>
    )
}

export default GroupedFilters
