import { useState, useEffect } from 'react'
import { AppliedFilters, AppliedOrder } from '../type'

import { TripoFilter } from '@triporate/triporate-filters'

function useFilterResults<T>(data: T[]): [T[], (filters: AppliedFilters[]) => void, (order?: AppliedOrder) => void] {
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilters[]>([])
    const [appliedOrder, setAppliedOrder] = useState<AppliedOrder>()
    const [processedData, setProcessedData] = useState<T[]>([])

    useEffect(() => {
        // Filter
        const filteredData = TripoFilter.filterResults<T>(data, appliedFilters)

        // Sort
        if (appliedOrder) {
            const sortedData = TripoFilter.orderResults<T>(filteredData, appliedOrder)
            setProcessedData(sortedData)
        } else {
            setProcessedData(filteredData)
        }
    }, [data, appliedFilters, appliedOrder])

    return [processedData, setAppliedFilters, setAppliedOrder]
}

export default useFilterResults
