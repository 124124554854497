import React, { FunctionComponent } from 'react'
import { Tooltip, Typography } from 'antd'
import { TripoPriceModel } from '../TripoPrice/TripoPriceModel'
import { toMoney } from 'src/utils'
import { TripoPolicyModel } from '../TripoPrice/TripoPolicyModel'
import { Icons, TripoIcon, validateIconType } from 'src/components/general'
import { TripoIconEnum, TripoIconStatusEnum } from 'src/components/general'

const { Title, Text } = Typography

export enum PriceSizeEnum {
    'S' = 5,
    'M' = 4,
    'L' = 3
}

enum PolicyStatus {
    secondary = 'secondary',
    success = 'success',
    warning = 'warning',
    danger = 'danger'
}
interface PriceProps {
    money: TripoPriceModel
    label?: string
    status?: 'secondary' | 'success' | 'warning' | 'danger' | string
    policy?: TripoPolicyModel
    size?: PriceSizeEnum
    note?: { icon: string; text: string }
}

export const validateAsPolicyStatus = (value?: string): PolicyStatus | undefined => {
    if (value && value in PolicyStatus) {
        return PolicyStatus[value]
    }
    return undefined
}

const Price: FunctionComponent<PriceProps> = ({ money, status, label, size, policy, note }): JSX.Element => {
    const isDecimal = !Number.isInteger(money.amount)
    return (
        <div className="price-container">
            {policy && policy?.policySurplus?.amount > 0 && (
                <TripoIcon
                    icon={TripoIconEnum.info}
                    color={TripoIconStatusEnum[policy.policyStatus]}
                    style={{ alignSelf: 'center', marginBottom: '42px' }}
                ></TripoIcon>
            )}
            <Tooltip placement="top" title={label}>
                <Title
                    className="price-amount"
                    style={{ marginBottom: '2px' }}
                    level={size}
                    type={validateAsPolicyStatus(status)}
                >
                    {toMoney(money, isDecimal)}
                </Title>
            </Tooltip>

            {note?.text && (
                <div>
                    <Icons icon={validateIconType(note.icon)} />
                    <Text style={{ marginLeft: '2px' }}>{note.text}</Text>
                </div>
            )}

            {label && <span className="price-pax-calculated">{label}</span>}
        </div>
    )
}

Price.defaultProps = {
    size: PriceSizeEnum.L
}

export default Price
