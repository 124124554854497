/* eslint-disable max-lines */
import React, { FunctionComponent, useState, useEffect } from 'react'
import FiltersUIContext from '../FiltersContext'
import {
    Config,
    AppliedFilters,
    ActiveTags,
    OnFilterChangeValue,
    FilterValues,
    Category,
    AppliedOrder,
    CurrentInputsData
} from '../type'
import { OrderConfig } from '../OrderInputs/type'
import { addNewAppliedFilters, cleanAppliedFilters } from './utils/appliedFilters'
import { addFilterValues, cleanFilterValues } from './utils/filterValues'
import { addNewActiveTags, cleanActiveTags } from './utils/activeTags'
interface FiltersProviderProps {
    config: Config[]
    applyFilters: (data: AppliedFilters[]) => void
    flushAllFlag?: unknown
    orderConfig?: OrderConfig
    applyOrder?: (data: AppliedOrder) => void
    currentInputsData: CurrentInputsData
}
const FiltersProvider: FunctionComponent<FiltersProviderProps> = ({
    config,
    applyFilters,
    flushAllFlag,
    children,
    orderConfig,
    applyOrder,
    currentInputsData
}) => {
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilters[]>([])
    const [activeTags, setActiveTags] = useState<ActiveTags[]>([])
    const [filtersValues, setFiltersValues] = useState<FilterValues>({})
    const [filtersCategories, setFiltersCategories] = useState<Category[]>([])
    const [activeTab, setActiveTab] = useState<Category>()
    const [tripType, setTripType] = useState(currentInputsData.tripType)

    useEffect(() => applyFilters(appliedFilters), [appliedFilters, applyFilters])
    useEffect(() => {
        setAppliedFilters([])
        setActiveTags([])
        setActiveTab(undefined)
        setFiltersValues({})
        setFiltersCategories([])
        setTripType(currentInputsData.tripType)
    }, [flushAllFlag, currentInputsData])

    useEffect(() => {
        if (!Array.isArray(config)) return
        const allCategories = config.map(({ category }) => category)
        const uniqueCategories = Array.from(new Set(allCategories))
        if (uniqueCategories) setFiltersCategories(uniqueCategories)
    }, [config, setFiltersCategories])

    useEffect(() => {
        // Clean tabs and filter values if filter is removed from config
        const currentConfigFilterIds = config.map(({ id }) => id)
        setActiveTags((prevState) => prevState.filter(({ id }) => currentConfigFilterIds.includes(id)))
        setFiltersValues((prevState) => {
            currentConfigFilterIds.forEach((id) => {
                if (id in prevState) delete prevState[id]
            })
            return prevState
        })
    }, [config])

    const addFilterById = ({
        id,
        type,
        tag,
        path,
        logic,
        category,
        value,
        newValue,
        rangeType,
        filterGroup,
        group
    }: OnFilterChangeValue) => {
        const autocompleteFilters = config.filter(
            (filter) => filter.autocompletedById && filter.id.includes(filter.autocompletedById)
        )
        setFiltersValues((prevState) => addFilterValues(prevState, id, value, autocompleteFilters, newValue))
        setAppliedFilters((prevState) =>
            addNewAppliedFilters(
                prevState,
                id,
                type,
                path,
                value,
                autocompleteFilters,
                newValue,
                logic,
                rangeType,
                filterGroup,
                group
            )
        )
        setActiveTags((prevState) =>
            addNewActiveTags(
                prevState,
                id,
                value,
                category,
                tag,
                type,
                autocompleteFilters,
                newValue,
                rangeType,
                filterGroup
            )
        )
    }

    const handleListValues = (id: string) => {
        const [cleanId, value] = id.split('-')
        setAppliedFilters((prevState) => cleanAppliedFilters(prevState, cleanId, value))
        setFiltersValues((prevState) => cleanFilterValues(prevState, cleanId, value))
        setActiveTags((prevState) => cleanActiveTags(prevState, cleanId, value))
    }

    const handleSimpleValue = (id: string, type: string) => {
        const item = config.filter((item) => item.id == id)
        setAppliedFilters((prevState) => prevState.filter((item) => item.id !== id))
        setFiltersValues((prevState) => {
            // prevents range inputs to lose handlers values
            if (type === 'range') {
                return { ...prevState, [id]: [item?.[0].range?.min, item?.[0].range?.max] }
            } else return { ...prevState, [id]: undefined }
        })
        setActiveTags((prevState) => prevState.filter((item) => item.id !== id))
    }

    const handleGeneralFilterSimpleValue = (id: string) => {
        setAppliedFilters((prevState) => prevState.filter((item) => !item.id.includes(id)))
        setFiltersValues((prevState) => ({ ...prevState, [id]: undefined }))
        setActiveTags((prevState) =>
            prevState.filter((item) => {
                if (id.includes('_')) id = id.split('_')[0]
                return !item.id.includes(id)
            })
        )
    }

    const cleanFilter = (id: string, type: string, filterGroup?: string) => {
        if (id.includes('-list')) return handleListValues(id)
        // for General filters
        if (filterGroup) return handleGeneralFilterSimpleValue(id)
        handleSimpleValue(id, type)
    }

    const onFilterChange = (data: OnFilterChangeValue) => {
        const { id, value, type, filterGroup } = data
        if (!value || (Array.isArray(value) && !value.length)) cleanFilter(id, type, filterGroup)
        else addFilterById(data)
    }

    const onOrderChange = (data: AppliedOrder) => {
        if (data.value) applyOrder?.(data)
    }

    const isTabCategory = (category: Category) => filtersCategories.indexOf(category) > 0

    const onTagClick = (category: Category) => {
        if (isTabCategory(category)) setActiveTab(category)
    }

    const onTabClick = (category: Category) => {
        if (isTabCategory(category)) setActiveTab('initial')
    }

    const clearAllFilters = () => appliedFilters.map(({ id, type, filterGroup }) => cleanFilter(id, type, filterGroup))
    return (
        <FiltersUIContext.Provider
            value={{
                config,
                tripType,
                setTripType,
                filtersValues,
                onFilterChange,
                categories: filtersCategories,
                activeTab,
                onTabChange: setActiveTab,
                activeTags,
                onTagClose: cleanFilter,
                onTagClick,
                onTabClick,
                orderConfig,
                onOrderChange,
                clearAllFilters
            }}
        >
            {children}
        </FiltersUIContext.Provider>
    )
}
export default FiltersProvider
