import React, { FunctionComponent } from 'react'
import { Modal } from 'antd'
import { useWindowMessagesHandler, MessageItem } from '../../../utils'

type IframeModalProps = {
    visible: boolean
    src: string
    closeModal: () => void
    messageItem: MessageItem[]
    className?: string
}

const IframeModal: FunctionComponent<IframeModalProps> = ({
    visible,
    closeModal,
    src,
    messageItem,
    className = 'iframe-modal'
}): JSX.Element => {
    useWindowMessagesHandler(messageItem)
    return (
        <Modal className={className} visible={visible} onCancel={closeModal}>
            <iframe src={src} />
        </Modal>
    )
}

export default IframeModal
