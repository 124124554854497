import React, { FunctionComponent } from 'react'
import { Tooltip } from 'antd'
import { Icons, IconEnum } from '../Icons'

type ValidSizes = 'big' | 'xl'

type SuitCaseIconProps = {
    count: number
    tooltip?: string
    size?: ValidSizes
    style?: {
        [key: string]: string
    }
}

const SuitCaseIcon: FunctionComponent<SuitCaseIconProps> = ({ count, tooltip, size, style }) => {
    const getIcon = (count: number) => {
        switch (true) {
            case count === 0:
                return IconEnum.noSuitecase
            case count === 1:
                return IconEnum.oneSuitecase
            case count === 2:
                return IconEnum.twoSuitecases
            case count === 3:
                return IconEnum.threeSuitecases
            case count > 3:
                return IconEnum.plusSuitecases
            default:
                return
        }
    }

    const getClassName = (count: number, iconSize?: ValidSizes) => {
        const baseClass = []

        const colorClass = count > 0 ? 'primary-color' : 'info-color'
        baseClass.push(colorClass)

        if (iconSize) {
            const iconSizeClass = `${iconSize}-suitcase-icon`
            baseClass.push(iconSizeClass)
        }

        return baseClass.join(' ')
    }

    return (
        <Tooltip placement="top" title={tooltip}>
            <span className={getClassName(count, size)}>
                <Icons style={style} icon={getIcon(count)} />
            </span>
        </Tooltip>
    )
}

export default SuitCaseIcon
