import { TripoPriceModel } from '../../components/data-display/TripoPrice/TripoPriceModel'

/**
 * Display money regarding currency and navigator's culture
 * toLocaleString -> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @param {TripoPriceModel} money
 * @return {*}  {string}
 */
const toMoney = (money: TripoPriceModel, decimals = false): string => {
    const config: Intl.NumberFormatOptions = { style: 'currency', currency: money.currency }
    if (!decimals) {
        config.minimumFractionDigits = 0
        config.maximumFractionDigits = 0
    }

    return money.amount.toLocaleString(undefined, config)
}
export { toMoney }
