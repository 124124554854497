import React, { FunctionComponent, useCallback } from 'react'
import { ErrorPageData } from './types'
import { Button, Result } from 'antd'

type ErrorPageProps = {
    errorPageInfo: ErrorPageData
}

const ErrorPageTemplate: FunctionComponent<ErrorPageProps> = ({ errorPageInfo }): JSX.Element => {
    const handleClick = useCallback(async () => {
        window.location.replace(errorPageInfo.callToActionURL)
    }, [errorPageInfo])

    return (
        <Result
            //TODO when images by error are created change src and remove status to show image - TP-6391
            // icon={<img src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />}
            status={errorPageInfo.code}
            title={errorPageInfo.code}
            subTitle={errorPageInfo?.text}
            extra={
                <Button onClick={handleClick} type="primary">
                    {errorPageInfo?.callToActionText}
                </Button>
            }
        />
    )
}

export default ErrorPageTemplate
