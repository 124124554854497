import React, { Fragment } from 'react'
import { IconEnum } from './types'

type ValidSizes = 'big'

type IconsProps = {
    icon?: IconEnum
    size?: ValidSizes
    style?: {
        [key: string]: string
    }
    className?: string
}

const Icons: React.FunctionComponent<IconsProps> = ({ icon, size, className, style }) => {
    const getClassName = (icon: string, iconSize?: ValidSizes, className?: string) => {
        const baseClass = [icon]

        if (className) {
            baseClass.push(className)
        }

        if (iconSize) {
            const iconSizeClass = `${iconSize}-suitcase-icon`
            baseClass.push(iconSizeClass)
        }

        return baseClass.join(' ')
    }
    return icon ? <i style={style} className={getClassName(icon, size, className)}></i> : <Fragment></Fragment>
}

export default Icons
