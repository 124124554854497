import React, { Fragment, FunctionComponent, useContext } from 'react'
import InputSwitch from './InputSwitch'

import FiltersUIContext from '../FiltersContext'

const OrderInputs: FunctionComponent = () => {
    const { orderConfig, onOrderChange } = useContext(FiltersUIContext)

    if (!orderConfig) {
        return <Fragment></Fragment>
    }

    return (
        <div className="orderInputs-container">
            <InputSwitch
                type={orderConfig.type}
                label={orderConfig.label}
                options={orderConfig.options}
                onChange={(value) =>
                    onOrderChange?.({
                        id: orderConfig.id,
                        path: `${orderConfig.path}.${value}`,
                        type: orderConfig.type,
                        value
                    })
                }
            />
        </div>
    )
}

export default OrderInputs
