import { useEffect } from 'react'
import { getUniverseThemeName, defaultUniverseTheme } from './universeThemeNames'
import themesColors from '../../../themesColors/themesColors.json'

export function useUniverseTheme(universe: string, lessImport = false): void {
    useEffect(() => {
        const themeName = getUniverseThemeName(universe)
        if (!lessImport) {
            require(`@triporate/triporate-design-system/dist/themesCss/${themeName}.theme.css`)
            require(`@triporate/triporate-design-system/dist/themesCss/${defaultUniverseTheme}.theme.css`)
        } else {
            require(`@triporate/triporate-design-system/dist/themesLess/${themeName}.less`)
        }
    }, [universe, lessImport])

    useEffect(() => {
        const htmlCollection = document.getElementsByTagName('html')
        if (htmlCollection && htmlCollection[0]) {
            htmlCollection[0].className = universe
        }
    }, [universe])

    useEffect(() => {
        const setRootColor = (colorKey: string, colorValue: string) =>
            document.documentElement.style.setProperty(colorKey, colorValue)

        const themeName = getUniverseThemeName(universe)
        const themeColor = themesColors[themeName] as { primaryColor: string; secondaryColor: string }
        const primaryColorKey = '--primary-color'
        setRootColor(primaryColorKey, themeColor.primaryColor)
        const secondaryColorKey = '--secondary-color'
        setRootColor(secondaryColorKey, themeColor.secondaryColor)
    }, [universe])
}
