import React, { Fragment } from 'react'
import { DescriptionsProps } from 'antd/lib/descriptions'
import { TripoPassengerModel } from './TripoPassengerModel'
import { TripoIcon, TripoIconStatusEnum, TripoIconEnum } from '../../general'
import { List, Typography, Space } from 'antd'

const { Paragraph, Text } = Typography

interface TripoPassengerProps extends DescriptionsProps {
    passenger: TripoPassengerModel[]
    pax: number
    passengerType: string
    type: string
}

const TripoPassengerList: React.FunctionComponent<TripoPassengerProps> = ({ passenger, pax, passengerType, type }) => {
    return (
        <Fragment>
            {passenger && (
                <Paragraph>
                    <Space size="small">
                        <TripoIcon icon={TripoIconEnum.person} color={TripoIconStatusEnum[type]}></TripoIcon>
                        <Text>
                            {pax} {passengerType}
                        </Text>
                    </Space>
                    {passenger.map((pass) => (
                        <List key={pass.id}>
                            - {pass.firstname} {pass.lastname}
                        </List>
                    ))}
                </Paragraph>
            )}
        </Fragment>
    )
}

export default TripoPassengerList
