import { AppliedFilters, Config } from '../../type'
import { formatAppliedFilterValue, handleGeneralFilters } from './utils'

export const addNewAppliedFilters = (
    prevState: AppliedFilters[],
    id: string,
    type: string,
    path: string,
    value: unknown,
    autocompleteFilters?: Config[],
    newValue?: unknown,
    logic?: string,
    rangeType?: string,
    filterGroup?: string,
    group?: string
): AppliedFilters[] => {
    const formatedValue = formatAppliedFilterValue(type, value, rangeType)
    let updateValues = updateAppliedfilters(prevState, id, type, path, formatedValue, logic, filterGroup, group)

    //if it's an autocomplete filter all groups of inputs should be filled when add a new value
    if (autocompleteFilters?.length && newValue) {
        const formatedNewValue = formatAppliedFilterValue(type, newValue)
        const filtersToAutocomplete = autocompleteFilters.filter((filter) => filter.id !== id)

        filtersToAutocomplete.map((filter) => {
            const { id, path } = filter
            const idAlreadyIncluded = checkAppliedFilterExist(prevState, id)
            let newIdValues: string[] = []

            //get values other related input
            prevState.map((appliedFilter) => {
                if (appliedFilter.id === id) newIdValues = appliedFilter.value as string[]
            })

            if (!idAlreadyIncluded)
                updateValues = [
                    ...updateValues,
                    { id: id, type, path: path, logic, value: [formatedNewValue], filterGroup, group }
                ]
            else {
                const castNewValue = formatedNewValue as string
                const newValueAlreadyExist = newIdValues.includes(castNewValue)

                if (!newValueAlreadyExist)
                    updateValues = updateAppliedfilters(
                        [...updateValues],
                        id,
                        type,
                        path,
                        [...newIdValues, castNewValue],
                        logic,
                        filterGroup,
                        group
                    )
            }
        })
    }

    return updateValues
}

const updateAppliedfilters = (
    prevState: AppliedFilters[],
    id: string,
    type: string,
    path: string,
    formatedValue: unknown,
    logic?: string,
    filterGroup?: string,
    group?: string
): AppliedFilters[] => {
    // set filterValues for general filters
    if (filterGroup) {
        return handleGeneralFilters(prevState, id, group)
    }

    // set filterValues for regular filters
    const included = checkAppliedFilterExist(prevState, id)
    return !included
        ? [...prevState, { id, type, path, logic, value: formatedValue, filterGroup, group }]
        : prevState.map((item) => {
              if (item.id === id) item.value = formatedValue
              return item
          })
}

const checkAppliedFilterExist = (prevState: AppliedFilters[], id: string) => prevState.some((item) => item.id === id)

export const cleanAppliedFilters = (prevState: AppliedFilters[], cleanId: string, value: string): AppliedFilters[] => {
    const AppliedFiltersList = prevState.reduce<AppliedFilters[]>((newState, item) => {
        if (item.id !== cleanId) {
            newState.push(item)
            return newState
        }

        if (Array.isArray(item.value)) {
            const valuesList = item.value as unknown[]

            const newValues = valuesList.filter((v) => v !== value)
            if (newValues.length) newState.push({ ...item, value: newValues })
        }

        return newState
    }, [])

    return AppliedFiltersList
}
