import React, { FunctionComponent, useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import FiltersUIContext from '../../FiltersContext'
import { FiltersGroups, Response } from '../../type'
import Filters from './Filters'

interface UnifiedFiltersProps {
    getSelectSearchAsyncOptions: (url: string, searchValue: string) => Promise<Response>
}

const UnifiedFilters: FunctionComponent<UnifiedFiltersProps> = ({ getSelectSearchAsyncOptions }) => {
    const { config, categories } = useContext(FiltersUIContext)
    const [groupedFilters, setGropued] = useState<FiltersGroups>([])

    useEffect(() => {
        if (!config) return
        const updateConfig = async () => {
            const group = _.groupBy(config, (conf) => conf.group)
            const gropuedFilters = Object.keys(group).map((key) => ({
                group: key,
                filters: group[key]
            }))
            setGropued(gropuedFilters)
        }

        updateConfig()
    }, [config, categories])

    return (
        <div className="unified-filters-container">
            <Filters filtersConfigs={groupedFilters} getSelectSearchAsyncOptions={getSelectSearchAsyncOptions} />
        </div>
    )
}

export default UnifiedFilters
