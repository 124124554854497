import React, { FunctionComponent, Fragment } from 'react'
import { Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
type CheckboxLabelProps = {
    label?: string
    note?: string
    noteBeforeLabel?: boolean
}

const CheckboxLabel: FunctionComponent<CheckboxLabelProps> = ({ label, note }) => (
    <Fragment>
        {note && (
            <span className="checkbox-label-note">
                <Tooltip title={note} placement="right">
                    <span>
                        <QuestionCircleOutlined />
                    </span>
                </Tooltip>
            </span>
        )}
        {label && <Text>{label}</Text>}
    </Fragment>
)

export default CheckboxLabel
