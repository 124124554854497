export enum IconEnum {
    flight = 'icon-plane-solid',
    hotel = 'icon-building-solid',
    train = 'icon-train-solid',
    car = 'icon-car-solid',
    calendar = 'icon-calendar-alt-solid',
    room = 'icon-senalhotel',
    room2 = 'icon-senalhotel2',
    marker = 'icon-map-marker-alt-solid',
    city = 'icon-city-solid',
    map = 'icon-map-marked-alt-solid',
    radio = 'icon-bullseye-solid',
    compass = 'icon-compass-solid',
    user = 'icon-user-solid',
    star = 'icon-star-solid',
    download = 'icon-paperclip-solid-1',
    departure = 'icon-plane-departure-solid',
    arrival = 'icon-plane-arrival-solid',
    noSuitecase = 'icon-no-suitcase',
    oneSuitecase = 'icon-suitcase1',
    twoSuitecases = 'icon-suitcase2',
    threeSuitecases = 'icon-suitcase3',
    plusSuitecases = 'icon-suitcase-plus-solid',
    fare = 'icon-ticket-alt-solid',
    seat = 'icon-seat',
    cancelable = 'icon-check-solid',
    arrowUp = 'icon-arrow-up-solid',
    arrowRight = 'icon-arrow-right-solid',
    arrowLeft = 'icon-arrow-left-solid',
    nonCancelable = 'icon-ban-solid',
    journey = 'icon-exchange-alt-solid-1',
    suitcase = 'icon-suitcase-rolling-solid',
    euro = 'icon-euro-sign-solid',
    check = 'icon-check-solid',
    negative = 'icon-times-solid',
    trainDeparture = 'icon-departure',
    trainArrival = 'icon-flag-checkered-solid',
    envelope = 'icon-envelope-regular',
    delete = 'icon-trash-alt-regular',
    edit = 'icon-pen-solid',
    building = 'icon-building-solid',
    office = 'icon-industry-solid',
    travellers = 'icon-user-friends-solid',
    clock = 'icon-clock-regular',
    undo = 'icon-undo-alt-solid',
    store = 'icon-store-alt-solid',
    suitcaseRolling = 'icon-suitcase-rolling-solid-1',
    gears = 'icon-gears',
    carDoor = 'icon-car-door',
    gasPump = 'icon-gas-pump-solid',
    driver = 'icon-driver-solid-1',
    fullspeed = 'icon-fullspeed',
    info = 'icon-info-circle-solid',
    meals = 'icon-concierge-bell-solid',
    nights = 'icon-moon-solid',
    images = 'icon-images-solid',
    cog = 'icon-cog-solid',
    clockSolid = 'icon-clock-solid',
    breakfast = 'icon-coffee-solid',
    logout = 'icon-sign-out-alt-solid',
    id = 'icon-id-card-solid'
}
